module.exports = [{
      plugin: require('/Users/akane/Desktop/shinonome code/Shinonome/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;"},
    },{
      plugin: require('/Users/akane/Desktop/shinonome code/Shinonome/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-34J9ZLNNDV"],"anonymize_ip":true,"cookie_expires":0},
    },{
      plugin: require('/Users/akane/Desktop/shinonome code/Shinonome/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/akane/Desktop/shinonome code/Shinonome/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/akane/Desktop/shinonome code/Shinonome/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/akane/Desktop/shinonome code/Shinonome/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-34J9ZLNNDV"],"anonymize_ip":true,"cookie_expires":0},
    }]
