// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/akane/Desktop/shinonome code/Shinonome/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-js": () => import("/Users/akane/Desktop/shinonome code/Shinonome/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-404-js": () => import("/Users/akane/Desktop/shinonome code/Shinonome/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/Users/akane/Desktop/shinonome code/Shinonome/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/akane/Desktop/shinonome code/Shinonome/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-member-js": () => import("/Users/akane/Desktop/shinonome code/Shinonome/src/pages/member.js" /* webpackChunkName: "component---src-pages-member-js" */),
  "component---src-pages-shop-js": () => import("/Users/akane/Desktop/shinonome code/Shinonome/src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-contact-success-js": () => import("/Users/akane/Desktop/shinonome code/Shinonome/src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */)
}

